import { EStatusDoc } from '@src/configs/interface.config'
import { TPostOrder, TQueryOrder } from '@src/modules'

import { request } from './config'

export const createOrder = (data: TPostOrder, token: string) =>
  request({ url: 'order', method: 'POST', data }, { token })
export const getListOrder = (params: TQueryOrder, token: string) =>
  request({ url: 'order', method: 'GET', params }, { token })
export const getOrderById = (id: string, token: string) => request({ url: `order/${id}`, method: 'GET' }, { token })
export const deleteOrder = (id: string, token: string) => request({ url: `order/${id}`, method: 'DELETE' }, { token })
export const approveOrderByIdProduct = (id: string, status: EStatusDoc, token: string) =>
  request({ url: `order/${id}/approve`, method: 'PATCH', data: { status } }, { token })
export const approveOrderById = (id: string, status: EStatusDoc, token: string) =>
  request({ url: `order/approve/${id}`, method: 'PATCH', data: { status } }, { token })
export const cancelOrderById = (id: string, token: string) =>
  request({ url: `order/cancel/${id}`, method: 'PATCH' }, { token })
