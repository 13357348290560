import { NError, NSuccess } from '@src/configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TPatchProductThickness, TPostProductThickness, TProductThickness, TQueryProductThickness } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import {
  createProductThickness,
  deleteProductThickness,
  getListProductThickness,
  getProductThicknessById,
  patchProductThickness,
} from '../apis'
import { DETAIL_PRODUCT_THICKNESS, GET_LIST_PRODUCT_THICKNESS } from '../keys'

export const useCreateProductThickness = () => {
  const accessToken = checkAuth()
  return useMutation((body: TPostProductThickness) => createProductThickness(body, accessToken), {
    onSuccess: (res: TResApi<TProductThickness>) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_THICKNESS])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useQueryListProductThickness = (params: TQueryProductThickness) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TProductThickness[]>>(
    [GET_LIST_PRODUCT_THICKNESS, JSON.stringify(params)],
    () => getListProductThickness(params, accessToken),
    { enabled: !!accessToken },
  )
}

export const useQueryProductThicknessById = (id: number) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TProductThickness>>(
    [DETAIL_PRODUCT_THICKNESS, id],
    () => getProductThicknessById(id, accessToken),
    {
      enabled: !!accessToken && !!id,
    },
  )
}

export const useMutationPatchProductThicknessById = () => {
  const accessToken = checkAuth()
  return useMutation(
    ({ id, data }: { id: number; data: TPatchProductThickness }) => patchProductThickness(id, data, accessToken),
    {
      onSuccess: (res: TResApi, { id }) => {
        queryClient.refetchQueries([GET_LIST_PRODUCT_THICKNESS])
        queryClient.invalidateQueries([DETAIL_PRODUCT_THICKNESS, id])
        notification.success({ message: NSuccess, description: res?.message })
      },
      onError: (error: TResApiErr) => {
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
}

export const useMutationRemoveProductThicknessById = () => {
  const accessToken = checkAuth()
  return useMutation((id: number) => deleteProductThickness(id, accessToken), {
    onSuccess: (res: TResApi, id) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_THICKNESS])
      queryClient.removeQueries([DETAIL_PRODUCT_THICKNESS, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}
