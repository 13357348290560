import {
  DashboardOutlined,
  FundOutlined,
  OrderedListOutlined,
  ContainerOutlined,
  ReadOutlined,
  SafetyCertificateOutlined,
  UserOutlined,
  DatabaseOutlined,
} from '@ant-design/icons'
import logoApp from '@assets/favicon.svg'
import Breadcrumb from '@components/widgets/Breadcrumb'
import { checkAuth } from '@libs/localStorage'
import UserDropdownAuth from '@src/components/widgets/UserDropdownAuth'
import { ACCESS } from '@src/configs/permission'
import { usePermissionCurrent } from '@src/hooks'
import { Col, Divider, Layout, Menu, MenuProps, Row } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const { Header, Content, Footer, Sider } = Layout

type MenuItem = Required<MenuProps>['items'][number]

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  keyName: ACCESS,
  permissions: ACCESS[],
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem => {
  if (!permissions.includes(keyName)) return null
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem
}

interface ILayoutApp {
  children: JSX.Element
}

function LayoutApp({ children }: ILayoutApp) {
  const location = useLocation()
  const token = checkAuth()
  const { permissions } = usePermissionCurrent(token)
  console.log(permissions)
  const [collapsed, setCollapsed] = useState(false)
  const [selectedKeyMenu, setSelectedKeyMenu] = useState<string[]>(['dashboard'])

  useEffect(() => {
    const pathSnippets = location.pathname.split('/').filter((i) => i)
    if (pathSnippets && pathSnippets?.length > 0) setSelectedKeyMenu(pathSnippets)
  }, [])

  const onSelectMenu = ({ keyPath }: { keyPath: string[] }) => {
    setSelectedKeyMenu(keyPath)
  }

  const menuItems: MenuItem[] = useMemo(
    () => [
      getItem(<Link to="/">Bảng điều khiển</Link>, 'dashboard', ACCESS.GENERAL, permissions, <DashboardOutlined />),
      getItem('Bài viết', 'postG', ACCESS.LIST_POST, permissions, <ReadOutlined />, [
        getItem(<Link to="/post">Danh sách bài viết</Link>, 'post', ACCESS.LIST_POST, permissions),
        getItem(<Link to="/create-post">Tạo bài viết</Link>, 'create-post', ACCESS.ADD_POST, permissions),
      ]),
      getItem('Chiến dịch', 'campaignG', ACCESS.LIST_CAMPAIGN, permissions, <FundOutlined />, [
        getItem(<Link to="/campaign">Danh sách chiến dịch</Link>, 'campaign', ACCESS.LIST_CAMPAIGN, permissions),
        getItem(<Link to="/create-campaign">Tạo chiến dịch</Link>, 'create-campaign', ACCESS.ADD_CAMPAIGN, permissions),
      ]),
      getItem('Thuộc tính sản phẩm', 'productPropertyG', ACCESS.LIST_PRODUCT, permissions, <OrderedListOutlined />, [
        getItem('Danh mục', 'categoryG', ACCESS.LIST_CATEGORY, permissions, null, [
          getItem(
            <Link to="/product-category">Danh sách danh mục</Link>,
            'category',
            ACCESS.LIST_CATEGORY,
            permissions,
          ),
          getItem(
            <Link to="/create-product-category">Tạo danh mục</Link>,
            'create-product-category',
            ACCESS.ADD_CATEGORY,
            permissions,
          ),
        ]),
        getItem('Giảm giá', 'discountG', ACCESS.LIST_DISCOUNT, permissions, null, [
          getItem(<Link to="/discount">Danh sách giảm giá</Link>, 'discount', ACCESS.LIST_DISCOUNT, permissions),
          getItem(
            <Link to="/create-discount">Tạo mã giảm giá</Link>,
            'create-discount',
            ACCESS.ADD_DISCOUNT,
            permissions,
          ),
        ]),
        getItem('Phụ kiện', 'accessoryG', ACCESS.LIST_ACCESSORY, permissions, null, [
          getItem(<Link to="/accessory">Danh sách phụ kiện</Link>, 'accessory', ACCESS.LIST_ACCESSORY, permissions),
          getItem(
            <Link to="/create-accessory">Tạo phụ kiện</Link>,
            'create-accessory',
            ACCESS.ADD_ACCESSORY,
            permissions,
          ),
        ]),
        getItem('Màu sắc', 'colorG', ACCESS.LIST_COLOR, permissions, null, [
          getItem(<Link to="/color">Danh sách màu sắc</Link>, 'color', ACCESS.LIST_COLOR, permissions),
          getItem(<Link to="/create-color">Tạo màu sắc</Link>, 'create-color', ACCESS.ADD_COLOR, permissions),
        ]),
        getItem('Xốp', 'foamG', ACCESS.LIST_FOAM, permissions, null, [
          getItem(<Link to="/foam">Danh sách xốp</Link>, 'foam', ACCESS.LIST_FOAM, permissions),
          getItem(<Link to="/create-foam">Tạo xốp</Link>, 'create-foam', ACCESS.ADD_FOAM, permissions),
        ]),
        getItem('Trần', 'panelG', ACCESS.LIST_PANEL, permissions, null, [
          getItem(<Link to="/panel">Danh sách trần</Link>, 'panel', ACCESS.LIST_PANEL, permissions),
          getItem(<Link to="/create-panel">Tạo trần</Link>, 'create-panel', ACCESS.ADD_PANEL, permissions),
        ]),
        getItem('Kích thước', 'sizeG', ACCESS.LIST_SIZE, permissions, null, [
          getItem(<Link to="/size">Danh sách thích thước</Link>, 'size', ACCESS.LIST_SIZE, permissions),
          getItem(<Link to="/create-size">Tạo kích thước</Link>, 'create-size', ACCESS.ADD_SIZE, permissions),
        ]),
        getItem('Bề mặt', 'surfaceG', ACCESS.LIST_SURFACE, permissions, null, [
          getItem(<Link to="/surface">Danh sách bề mặt</Link>, 'surface', ACCESS.LIST_SURFACE, permissions),
          getItem(<Link to="/create-surface">Tạo bề mặt</Link>, 'create-surface', ACCESS.ADD_SURFACE, permissions),
        ]),
        getItem('Độ dày', 'thicknessG', ACCESS.LIST_THICKNESS, permissions, null, [
          getItem(<Link to="/thickness">Danh sách độ dày</Link>, 'thickness', ACCESS.LIST_THICKNESS, permissions),
          getItem(
            <Link to="/create-thickness">Tạo độ dày</Link>,
            'create-thickness',
            ACCESS.ADD_THICKNESS,
            permissions,
          ),
        ]),
        getItem('Kiểu', 'product-typeG', ACCESS.LIST_PRODUCT_TYPE, permissions, null, [
          getItem(
            <Link to="/product-type">Danh sách kiểu</Link>,
            'product-type',
            ACCESS.LIST_PRODUCT_TYPE,
            permissions,
          ),
          getItem(
            <Link to="/create-product-type">Tạo kiểu</Link>,
            'create-product-type',
            ACCESS.ADD_PRODUCT_TYPE,
            permissions,
          ),
        ]),
        getItem('Tỷ trọng', 'list-densityG', ACCESS.LIST_DENSITY, permissions, null, [
          getItem(<Link to="/list-density">Danh sách tỷ trọng</Link>, 'list-density', ACCESS.LIST_DENSITY, permissions),
          getItem(
            <Link to="/create-density">Tạo tỷ trọng</Link>,
            'create-list-density',
            ACCESS.ADD_DENSITY,
            permissions,
          ),
        ]),
      ]),
      getItem('Đơn hàng mua sắm', 'order', ACCESS.LIST_ORDER, permissions, <DatabaseOutlined />, [
        getItem(<Link to="/order">Đơn hàng tổng hợp</Link>, 'order', ACCESS.LIST_ORDER, permissions),
        getItem(<Link to="/group-by">Đơn hàng mua chung</Link>, 'group-by', ACCESS.LIST_ORDER, permissions),
        getItem(<Link to="/direct-by">Đơn hàng mua riêng</Link>, 'direct-by', ACCESS.LIST_ORDER, permissions),
      ]),
      getItem(
        <Link to="/sales-order">Đơn hàng đại lý</Link>,
        'sales-order',
        ACCESS.LIST_SALES_ORDER,
        permissions,
        <ContainerOutlined />,
      ),
      getItem(<Link to="/user">Người dùng</Link>, 'user', ACCESS.LIST_USER, permissions, <UserOutlined />),
      getItem(<Link to="/role">Quyền</Link>, 'role', ACCESS.LIST_ROLE, permissions, <SafetyCertificateOutlined />),
    ],
    [permissions],
  )

  return (
    <Layout style={{ minHeight: '100vh', padding: 0 }}>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1200,
          width: '100%',
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <img src={logoApp} alt="logo" width={120} className="logo-app" />
            </Col>
            <Col>
              <UserDropdownAuth />
            </Col>
          </Row>
        </Col>
      </Header>
      <Layout style={{ overflow: 'hidden' }}>
        <Sider width={230} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} theme="light">
          <Menu
            mode="inline"
            style={{ height: '100%', borderRight: 0 }}
            items={menuItems}
            theme="light"
            defaultSelectedKeys={selectedKeyMenu}
            onSelect={onSelectMenu}
          />
        </Sider>
        <Layout style={{ padding: '0 24px 0' }}>
          <Row style={{ margin: '16px 0' }}>
            <Breadcrumb />
          </Row>
          <Divider style={{ margin: 0 }} />
          <Content
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              margin: 0,
              minHeight: 280,
            }}
          >
            <Row>{children}</Row>
          </Content>
          <Footer
            style={{
              paddingTop: 24,
              paddingBottom: 24,
              paddingLeft: 0,
              paddingRight: 0,
              textAlign: 'center',
            }}
          >
            {'Designed and Developer by '}
            <a href="https://twinger.vn/" target="_blank" rel="noreferrer">
              Twinger
            </a>
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default LayoutApp
