import { TPatchProductThickness, TPostProductThickness, TQueryProductThickness } from '@src/modules'

import { request } from './config'

export const createProductThickness = (body: TPostProductThickness, token: string) =>
  request({ url: 'product-thickness', method: 'POST', data: body }, { token })
export const getListProductThickness = (params: TQueryProductThickness, token: string) =>
  request({ url: 'product-thickness', method: 'GET', params }, { token })
export const getProductThicknessById = (id: number, token: string) =>
  request({ url: `product-thickness/${id}`, method: 'GET' }, { token })
export const patchProductThickness = (id: number, body: TPatchProductThickness, token: string) =>
  request({ url: `product-thickness/${id}`, method: 'PATCH', data: body }, { token })
export const deleteProductThickness = (id: number, token: string) =>
  request({ url: `product-thickness/${id}`, method: 'DELETE' }, { token })
