import { TPatchProductColor, TPostProductColor, TQueryProductColor } from '@src/modules'

import { request } from './config'

export const createProductColor = (body: TPostProductColor, token: string) =>
  request({ url: 'product-color', method: 'POST', data: body }, { token })
export const getListProductColor = (params: TQueryProductColor, token: string) =>
  request({ url: 'product-color', method: 'GET', params }, { token })
export const getProductColorById = (id: number, token: string) =>
  request({ url: `product-color/${id}`, method: 'GET' }, { token })
export const patchProductColor = (id: number, body: TPatchProductColor, token: string) =>
  request({ url: `product-color/${id}`, method: 'PATCH', data: body }, { token })
export const deleteProductColor = (id: number, token: string) =>
  request({ url: `product-color/${id}`, method: 'DELETE' }, { token })
