import { EStatusDoc, TQueryParamsGetData } from '@src/configs/interface.config'

import { TUser } from './user'
import { TProduct } from './product'
import { TOrder } from './order'

export enum ETypeCampaign {
  DIRECT_PURCHASE = 'DIRECT_PURCHASE',
  GROUP_PURCHASE = 'GROUP_PURCHASE',
}
export const typeCampaign: Record<string, string> = {
  DIRECT_PURCHASE: 'Mua trực tiếp',
  GROUP_PURCHASE: 'Mua chung',
}
export type TCampaign = {
  createdAt: Date
  updatedAt: Date
  status: EStatusDoc
  id: number
  type: ETypeCampaign
  statusOrder: EStatusDoc
  name: string
  startAt: Date
  endAt: Date
  author: TUser
  editBy: TUser
  product: TProduct[]
  order: TOrder[]
}
export type TPostCampaign = {
  status?: EStatusDoc
  name: string
  type: ETypeCampaign
  startAt: Date
  endAt: Date
}
export type TPatchCampaign = Partial<TPostCampaign>
export type TQueryCampaign = TQueryParamsGetData<{
  type?: ETypeCampaign
  status?: EStatusDoc
  startAt?: Date
  endAt?: Date
}>
