import { ACCESS } from '@src/configs/permission'
import lazyLoading from '@src/components/widgets/LazyLoading'

import { TRouteConfig } from '../routeConfig'

const List = lazyLoading(() => import('@pages/campaign'))
const Create = lazyLoading(() => import('@pages/campaign/create'))
const Detail = lazyLoading(() => import('@pages/campaign/detail'))
const CreateProduct = lazyLoading(() => import('@pages/campaign/product/create'))
const DetailProduct = lazyLoading(() => import('@pages/campaign/product/detail'))

const campaignRouter: TRouteConfig[] = [
  {
    path: '/campaign',
    Element: List,
    key: ACCESS.LIST_CAMPAIGN,
  },
  {
    path: '/create-campaign',
    Element: Create,
    key: ACCESS.ADD_CAMPAIGN,
  },
  {
    path: '/campaign/:id',
    Element: Detail,
    key: ACCESS.VIEW_CAMPAIGN,
  },
  {
    path: '/campaign/:id/create-product',
    Element: CreateProduct,
    key: ACCESS.ADD_PRODUCT,
  },
  {
    path: '/campaign/:id/product/:productId',
    Element: DetailProduct,
    key: ACCESS.VIEW_PRODUCT,
  },
]

export { campaignRouter }
