import { TPatchProduct, TPostProduct, TQueryProduct } from '@src/modules'

import { request } from './config'

export const createProduct = (body: TPostProduct, token: string) =>
  request({ url: 'product', method: 'POST', data: body }, { token })
export const getListProduct = (params: TQueryProduct, token: string) =>
  request({ url: 'product', method: 'GET', params }, { token })
export const getProductById = (id: number, token: string) => request({ url: `product/${id}`, method: 'GET' }, { token })
export const patchProduct = (id: number, body: TPatchProduct, token: string) =>
  request({ url: `product/${id}`, method: 'PATCH', data: body }, { token })
export const deleteProduct = (id: number, token: string) =>
  request({ url: `product/${id}`, method: 'DELETE' }, { token })
