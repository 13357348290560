import { NError, NSuccess } from '@src/configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TPatchProduct, TPostProduct, TProduct, TQueryProduct } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import { createProduct, deleteProduct, getListProduct, getProductById, patchProduct } from '../apis'
import { DETAIL_PRODUCT, GET_LIST_PRODUCT } from '../keys'

export const useCreateProduct = () => {
  const accessToken = checkAuth()
  return useMutation((body: TPostProduct) => createProduct(body, accessToken), {
    onSuccess: (res: TResApi<TProduct>) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useQueryListProduct = (params: TQueryProduct) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TProduct[]>>(
    [GET_LIST_PRODUCT, JSON.stringify(params)],
    () => getListProduct(params, accessToken),
    { enabled: !!accessToken },
  )
}

export const useQueryProductById = (id: number) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TProduct>>([DETAIL_PRODUCT, id], () => getProductById(id, accessToken), {
    enabled: !!accessToken && !!id,
  })
}

export const useMutationPatchProductById = () => {
  const accessToken = checkAuth()
  return useMutation(({ id, data }: { id: number; data: TPatchProduct }) => patchProduct(id, data, accessToken), {
    onSuccess: (res: TResApi, { id }) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT])
      queryClient.invalidateQueries([DETAIL_PRODUCT, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useMutationRemoveProductById = () => {
  const accessToken = checkAuth()
  return useMutation((id: number) => deleteProduct(id, accessToken), {
    onSuccess: (res: TResApi, id) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT])
      queryClient.removeQueries([DETAIL_PRODUCT, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}
