import { TPatchProductFoam, TPostProductFoam, TQueryProductFoam } from '@src/modules'

import { request } from './config'

export const createProductFoam = (body: TPostProductFoam, token: string) =>
  request({ url: 'product-foam', method: 'POST', data: body }, { token })
export const getListProductFoam = (params: TQueryProductFoam, token: string) =>
  request({ url: 'product-foam', method: 'GET', params }, { token })
export const getProductFoamById = (id: number, token: string) =>
  request({ url: `product-foam/${id}`, method: 'GET' }, { token })
export const patchProductFoam = (id: number, body: TPatchProductFoam, token: string) =>
  request({ url: `product-foam/${id}`, method: 'PATCH', data: body }, { token })
export const deleteProductFoam = (id: number, token: string) =>
  request({ url: `product-foam/${id}`, method: 'DELETE' }, { token })
