import { ACCESS } from '@src/configs/permission'
import lazyLoading from '@src/components/widgets/LazyLoading'

import { TRouteConfig } from '../routeConfig'

const List = lazyLoading(() => import('@src/pages/product-property/category'))
const Create = lazyLoading(() => import('@src/pages/product-property/category/create'))
const Detail = lazyLoading(() => import('@src/pages/product-property/category/detail'))

const categoryRouter: TRouteConfig[] = [
  {
    path: '/product-category',
    Element: List,
    key: ACCESS.LIST_CATEGORY,
  },
  {
    path: '/create-product-category',
    Element: Create,
    key: ACCESS.ADD_CATEGORY,
  },
  {
    path: '/product-category/:id',
    Element: Detail,
    key: ACCESS.VIEW_CATEGORY,
  },
]

export { categoryRouter }
