import { NError, NSuccess } from '@src/configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TPatchProductDensity, TPostProductDensity, TProductDensity, TQueryProductDensity } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import {
  createProductDensity,
  deleteProductDensity,
  getListProductDensity,
  getProductDensityById,
  patchProductDensity,
} from '../apis'
import { DETAIL_PRODUCT_DENSITY, GET_LIST_PRODUCT_DENSITY } from '../keys'

export const useCreateProductDensity = () => {
  const accessToken = checkAuth()
  return useMutation((body: TPostProductDensity) => createProductDensity(body, accessToken), {
    onSuccess: (res: TResApi<TProductDensity>) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_DENSITY])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useQueryListProductDensity = (params: TQueryProductDensity) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TProductDensity[]>>(
    [GET_LIST_PRODUCT_DENSITY, JSON.stringify(params)],
    () => getListProductDensity(params, accessToken),
    { enabled: !!accessToken },
  )
}

export const useQueryProductDensityById = (id: number) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TProductDensity>>(
    [DETAIL_PRODUCT_DENSITY, id],
    () => getProductDensityById(id, accessToken),
    {
      enabled: !!accessToken && !!id,
    },
  )
}

export const useMutationPatchProductDensityById = () => {
  const accessToken = checkAuth()
  return useMutation(
    ({ id, data }: { id: number; data: TPatchProductDensity }) => patchProductDensity(id, data, accessToken),
    {
      onSuccess: (res: TResApi, { id }) => {
        queryClient.refetchQueries([GET_LIST_PRODUCT_DENSITY])
        queryClient.invalidateQueries([DETAIL_PRODUCT_DENSITY, id])
        notification.success({ message: NSuccess, description: res?.message })
      },
      onError: (error: TResApiErr) => {
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
}

export const useMutationRemoveProductDensityById = () => {
  const accessToken = checkAuth()
  return useMutation((id: number) => deleteProductDensity(id, accessToken), {
    onSuccess: (res: TResApi, id) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_DENSITY])
      queryClient.removeQueries([DETAIL_PRODUCT_DENSITY, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}
