import { TPatchProductSurface, TPostProductSurface, TQueryProductSurface } from '@src/modules'

import { request } from './config'

export const createProductSurface = (body: TPostProductSurface, token: string) =>
  request({ url: 'product-surface', method: 'POST', data: body }, { token })
export const getListProductSurface = (params: TQueryProductSurface, token: string) =>
  request({ url: 'product-surface', method: 'GET', params }, { token })
export const getProductSurfaceById = (id: number, token: string) =>
  request({ url: `product-surface/${id}`, method: 'GET' }, { token })
export const patchProductSurface = (id: number, body: TPatchProductSurface, token: string) =>
  request({ url: `product-surface/${id}`, method: 'PATCH', data: body }, { token })
export const deleteProductSurface = (id: number, token: string) =>
  request({ url: `product-surface/${id}`, method: 'DELETE' }, { token })
