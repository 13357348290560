import { ACCESS } from '@src/configs/permission'
import lazyLoading from '@src/components/widgets/LazyLoading'

import { TRouteConfig } from '../routeConfig'

const List = lazyLoading(() => import('@src/pages/product-property/surface'))
const Create = lazyLoading(() => import('@src/pages/product-property/surface/create'))
const Detail = lazyLoading(() => import('@src/pages/product-property/surface/detail'))

const surfaceRouter: TRouteConfig[] = [
  {
    path: '/surface',
    Element: List,
    key: ACCESS.LIST_SURFACE,
  },
  {
    path: '/create-surface',
    Element: Create,
    key: ACCESS.ADD_SURFACE,
  },
  {
    path: '/surface/:id',
    Element: Detail,
    key: ACCESS.VIEW_SURFACE,
  },
]

export { surfaceRouter }
