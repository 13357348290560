import { NError, NSuccess } from '@src/configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { ETypeCampaign, TCampaign, TPatchCampaign, TPostCampaign, TQueryCampaign } from '@src/modules/campaign'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import {
  createCampaign,
  deleteCampaign,
  findCurrentCampaign,
  getCampaignById,
  getListCampaign,
  patchCampaign,
} from '../apis'
import { DETAIL_CAMPAIGN, GET_CURRENT_CAMPAIGN, GET_LIST_CAMPAIGN, GET_LIST_PRODUCT } from '../keys'

export const useCreateCampaign = () => {
  const accessToken = checkAuth()
  return useMutation((body: TPostCampaign) => createCampaign(body, accessToken), {
    onSuccess: (res: TResApi<TCampaign>) => {
      queryClient.refetchQueries([GET_LIST_CAMPAIGN])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useQueryListCampaign = (params: TQueryCampaign) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TCampaign[]>>(
    [GET_LIST_CAMPAIGN, JSON.stringify(params)],
    () => getListCampaign(params, accessToken),
    { enabled: !!accessToken },
  )
}

export const useQueryCurrentCampaign = (type: ETypeCampaign) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TCampaign>>([GET_CURRENT_CAMPAIGN, type], () => findCurrentCampaign(type, accessToken), {
    enabled: !!accessToken,
  })
}

export const useQueryDetailCampaign = (id: number) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TCampaign>>([DETAIL_CAMPAIGN, id], () => getCampaignById(id, accessToken), {
    enabled: !!accessToken && !!id,
  })
}

export const useMutationPatchCampaign = () => {
  const accessToken = checkAuth()
  return useMutation(({ id, data }: { id: number; data: TPatchCampaign }) => patchCampaign(id, data, accessToken), {
    onSuccess: (res: TResApi, { id }) => {
      queryClient.refetchQueries([GET_LIST_CAMPAIGN])
      queryClient.invalidateQueries([DETAIL_CAMPAIGN, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useMutationRemoveCampaign = () => {
  const accessToken = checkAuth()
  return useMutation((id: number) => deleteCampaign(id, accessToken), {
    onSuccess: (res: TResApi, id) => {
      queryClient.refetchQueries([GET_LIST_CAMPAIGN])
      queryClient.refetchQueries([GET_LIST_PRODUCT])
      queryClient.removeQueries([DETAIL_CAMPAIGN, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}
