import { EStatusDoc, TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TOrder, TPostOrder, TQueryOrder } from '@src/modules'
import { useMutation, useQuery } from 'react-query'
import { notification } from 'antd'
import { NError, NSuccess } from '@src/configs/const.config'

import { DETAIL_ORDER, GET_LIST_ORDER } from '../keys'
import {
  approveOrderById,
  approveOrderByIdProduct,
  createOrder,
  deleteOrder,
  getListOrder,
  getOrderById,
} from '../apis'
import { queryClient } from '..'

export const useMutationCreateOrder = () => {
  const accessToken = checkAuth()
  return useMutation((body: TPostOrder) => createOrder(body, accessToken), {
    onSuccess: (res: TResApi<TOrder>) => {
      queryClient.refetchQueries([GET_LIST_ORDER])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      // [TODO] ...
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useQueryListOrder = (params: TQueryOrder) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TOrder[]>>(
    [GET_LIST_ORDER, JSON.stringify(params)],
    () => getListOrder(params, accessToken),
    { enabled: !!accessToken },
  )
}

export const useQueryDetailOrder = (id: string) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TOrder>>([DETAIL_ORDER, id], () => getOrderById(id, accessToken), {
    enabled: !!accessToken,
  })
}

export const useMutationRemoveOrderById = () => {
  const accessToken = checkAuth()
  return useMutation((id: string) => deleteOrder(id, accessToken), {
    onSuccess: (res: TResApi, id) => {
      queryClient.refetchQueries([GET_LIST_ORDER])
      queryClient.removeQueries([DETAIL_ORDER, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useApproveOrderByIdProduct = () => {
  const accessToken = checkAuth()
  return useMutation(
    ({ id, status }: { id: string; status: EStatusDoc }) => approveOrderByIdProduct(id, status, accessToken),
    {
      onSuccess: (res: TResApi, { id }) => {
        queryClient.refetchQueries([GET_LIST_ORDER])
        queryClient.invalidateQueries([DETAIL_ORDER, id])
        notification.success({ message: NSuccess, description: res?.message })
      },
      onError: (error: TResApiErr) => {
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
}

export const useApproveOrderById = () => {
  const accessToken = checkAuth()
  return useMutation(
    ({ id, status }: { id: string; status: EStatusDoc }) => approveOrderById(id, status, accessToken),
    {
      onSuccess: (res: TResApi, { id }) => {
        queryClient.refetchQueries([GET_LIST_ORDER])
        queryClient.invalidateQueries([DETAIL_ORDER, id])
        notification.success({ message: NSuccess, description: res?.message })
      },
      onError: (error: TResApiErr) => {
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
}
