import { TSignIn } from '@modules/auth'
import { TPatchUser } from '@src/modules/user'

import { request } from './config'

export const signIn = (body: TSignIn) => request({ url: 'auth/login', method: 'POST', data: body })
export const signOut = (token: string) => request({ url: 'auth/logout', method: 'POST' }, { token })
export const refreshToken = (token: string, refreshToken: string) =>
  request({ url: 'auth/refresh-token', method: 'POST', data: { refreshToken } }, { token })
export const patchPassUserById = (id: number, data: TPatchUser) => request({ url: `auth/${id}`, method: 'PATCH', data })
