import { ACCESS } from '@src/configs/permission'
import lazyLoading from '@src/components/widgets/LazyLoading'

import { TRouteConfig } from '../routeConfig'

const List = lazyLoading(() => import('@src/pages/product-property/discount'))
const Create = lazyLoading(() => import('@src/pages/product-property/discount/create'))
const Detail = lazyLoading(() => import('@src/pages/product-property/discount/detail'))

const discountRouter: TRouteConfig[] = [
  {
    path: '/discount',
    Element: List,
    key: ACCESS.LIST_DISCOUNT,
  },
  {
    path: '/create-discount',
    Element: Create,
    key: ACCESS.ADD_DISCOUNT,
  },
  {
    path: '/discount/:id',
    Element: Detail,
    key: ACCESS.VIEW_DISCOUNT,
  },
]

export { discountRouter }
