import { FormLabelAlign } from 'antd/es/form/interface'
import { ColProps } from 'antd'
import { ETypeCampaign } from '@src/modules'

import { ELanguage, EOrder, EOrderBy, EStatusDoc, TStatusDoc } from './interface.config'
import { BASE_URL } from './api.config'

export const ROLE_INIT = ['ADMINISTRATOR', 'EDITOR', 'GUEST']

export const FORMAT_TIME_DEFAULT = 'YYYY-MM-DD HH:mm:ss'
export const FORMAT_DATE_DEFAULT = 'YYYY-MM-DD'

export const FOLDER_ROOT_ID = '637538b18bb90293ce4a39dd'
export const TAXONOMY_ROOT_ID = '60ab1c94c974f54870e9e64c'
export const NSuccess = 'Notification Success'
export const NError = 'Notification Error'
export const NWarning = 'Notification Warning'

export const ACTION_UPLOAD = () => `${BASE_URL}/media/upload`

export const LANGUAGE_DEFAULT = ELanguage.VI

export const LANGUAGE_CODE = [ELanguage.VI, ELanguage.EN]

export const labelStyle: { labelAlign: FormLabelAlign; labelCol: ColProps; wrapperCol: ColProps } = {
  labelCol: { xs: 24, sm: 24, md: 8, lg: 6, xl: 5 },
  wrapperCol: { xs: 24, sm: 24, md: 16, lg: 18, xl: 19 },
  labelAlign: 'left',
}
export const labelStyleSpan20 = {
  ...labelStyle,
  labelCol: { span: 20 },
}

export const labelChildren: { labelAlign: FormLabelAlign; labelCol: ColProps } = {
  ...labelStyle,
  labelAlign: 'right',
}

export const STATUS_DOCUMENT_SELECT: TStatusDoc[] = [
  { key: 1, value: EStatusDoc.ACTIVE, status: 'success' },
  { key: 2, value: EStatusDoc.INACTIVE, status: 'error' },
  { key: 3, value: EStatusDoc.DRAFT, status: 'default' },
]

export const STATUS_ORDER_SELECT: TStatusDoc[] = [
  { key: 1, value: EStatusDoc.PENDING, status: 'warning' },
  { key: 2, value: EStatusDoc.CANCELED, status: 'error' },
  { key: 3, value: EStatusDoc.CONFIRMED, status: 'success' },
]

export const STATUS_PRODUCT_SELECT: TStatusDoc[] = [
  ...STATUS_DOCUMENT_SELECT,
  { key: 4, value: EStatusDoc.PENDING, status: 'processing' },
]

export const STATUS_DOCUMENT_FILTER: {
  text: string
  value: EStatusDoc
}[] = [
  { text: EStatusDoc.ACTIVE.toLocaleUpperCase(), value: EStatusDoc.ACTIVE },
  { text: EStatusDoc.INACTIVE.toLocaleUpperCase(), value: EStatusDoc.INACTIVE },
  { text: EStatusDoc.DRAFT.toLocaleUpperCase(), value: EStatusDoc.DRAFT },
]
export const SATISFIED_DOCUMENT_FILTER: {
  text: string
  value: number
}[] = [
  { text: 'CÓ ĐÁNH GIÁ', value: 1 },
  { text: 'KHÔNG ĐÁNH GIÁ', value: 0 },
]

export const STATUS_ORDER_FILTER: {
  text: string
  value: EStatusDoc
}[] = [
  { text: EStatusDoc.CANCELED.toLocaleUpperCase(), value: EStatusDoc.CANCELED },
  { text: EStatusDoc.PENDING.toLocaleUpperCase(), value: EStatusDoc.PENDING },
  { text: EStatusDoc.TRANSPORTING.toLocaleUpperCase(), value: EStatusDoc.TRANSPORTING },
  { text: EStatusDoc.DELIVERED.toLocaleUpperCase(), value: EStatusDoc.DELIVERED },
  { text: EStatusDoc.CONFIRMED.toLocaleUpperCase(), value: EStatusDoc.CONFIRMED },
]

export const CAMPAIGN_FILTER: {
  text: string
  value: ETypeCampaign
}[] = [
  { text: 'Mua trực tiếp', value: ETypeCampaign.DIRECT_PURCHASE },
  { text: 'Mua chung', value: ETypeCampaign.GROUP_PURCHASE },
]

export const STATUS_PRODUCT_FILTER: {
  text: string
  value: EStatusDoc
}[] = [...STATUS_DOCUMENT_FILTER, { text: EStatusDoc.PENDING.toLocaleUpperCase(), value: EStatusDoc.PENDING }]

export const QUERY_ALL_BASE_DATA = { limit: 0, orderBy: EOrderBy.NAME, order: EOrder.ASC }
