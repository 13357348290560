import { NError, NSuccess } from '@src/configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TCategory, TPatchCategory, TPostCategory, TQueryCategory } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import { createCategory, deleteCategory, getCategoryById, getListCategory, patchCategory } from '../apis'
import { DETAIL_CATEGORY, GET_LIST_CATEGORY } from '../keys'

export const useCreateCategory = () => {
  const accessToken = checkAuth()
  return useMutation((body: TPostCategory) => createCategory(body, accessToken), {
    onSuccess: (res: TResApi<TCategory>) => {
      queryClient.refetchQueries([GET_LIST_CATEGORY])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useQueryListCategory = (params: TQueryCategory) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TCategory[]>>(
    [GET_LIST_CATEGORY, JSON.stringify(params)],
    () => getListCategory(params, accessToken),
    { enabled: !!accessToken },
  )
}

export const useQueryCategoryById = (id: number) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TCategory>>([DETAIL_CATEGORY, id], () => getCategoryById(id, accessToken), {
    enabled: !!accessToken && !!id,
  })
}

export const useMutationPatchCategory = () => {
  const accessToken = checkAuth()
  return useMutation(({ id, data }: { id: number; data: TPatchCategory }) => patchCategory(id, data, accessToken), {
    onSuccess: (res: TResApi, { id }) => {
      queryClient.refetchQueries([GET_LIST_CATEGORY])
      queryClient.invalidateQueries([DETAIL_CATEGORY, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useMutationRemoveCategory = () => {
  const accessToken = checkAuth()
  return useMutation((id: number) => deleteCategory(id, accessToken), {
    onSuccess: (res: TResApi, id) => {
      queryClient.refetchQueries([GET_LIST_CATEGORY])
      queryClient.removeQueries([DETAIL_CATEGORY, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}
