import { ACCESS } from '@src/configs/permission'
import lazyLoading from '@src/components/widgets/LazyLoading'

import { TRouteConfig } from '../routeConfig'

const List = lazyLoading(() => import('@src/pages/product-property/thickness'))
const Create = lazyLoading(() => import('@src/pages/product-property/thickness/create'))
const Detail = lazyLoading(() => import('@src/pages/product-property/thickness/detail'))

const thicknessRouter: TRouteConfig[] = [
  {
    path: '/thickness',
    Element: List,
    key: ACCESS.LIST_THICKNESS,
  },
  {
    path: '/create-thickness',
    Element: Create,
    key: ACCESS.ADD_THICKNESS,
  },
  {
    path: '/thickness/:id',
    Element: Detail,
    key: ACCESS.VIEW_THICKNESS,
  },
]

export { thicknessRouter }
