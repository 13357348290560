import { NError, NSuccess } from '@src/configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TDiscountRule, TPatchDiscountRule, TPostDiscountRule, TQueryDiscountRule } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import { createDiscount, deleteDiscount, getDiscountById, getListDiscount, patchDiscount } from '../apis'
import { DETAIL_DISCOUNT_RULE, GET_LIST_DISCOUNT_RULE } from '../keys'

export const useCreateDiscount = () => {
  const accessToken = checkAuth()
  return useMutation((body: TPostDiscountRule) => createDiscount(body, accessToken), {
    onSuccess: (res: TResApi<TDiscountRule>) => {
      queryClient.refetchQueries([GET_LIST_DISCOUNT_RULE])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useQueryListDiscount = (params: TQueryDiscountRule) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TDiscountRule[]>>(
    [GET_LIST_DISCOUNT_RULE, JSON.stringify(params)],
    () => getListDiscount(params, accessToken),
    { enabled: !!accessToken },
  )
}

export const useQueryDiscountById = (id: number) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TDiscountRule>>([DETAIL_DISCOUNT_RULE, id], () => getDiscountById(id, accessToken), {
    enabled: !!accessToken && !!id,
  })
}

export const useMutationPatchDiscountById = () => {
  const accessToken = checkAuth()
  return useMutation(({ id, data }: { id: number; data: TPatchDiscountRule }) => patchDiscount(id, data, accessToken), {
    onSuccess: (res: TResApi, { id }) => {
      queryClient.refetchQueries([GET_LIST_DISCOUNT_RULE])
      queryClient.invalidateQueries([DETAIL_DISCOUNT_RULE, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useMutationRemoveDiscountById = () => {
  const accessToken = checkAuth()
  return useMutation((id: number) => deleteDiscount(id, accessToken), {
    onSuccess: (res: TResApi, id) => {
      queryClient.refetchQueries([GET_LIST_DISCOUNT_RULE])
      queryClient.removeQueries([DETAIL_DISCOUNT_RULE, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}
