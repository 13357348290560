import { NError, NSuccess } from '@src/configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TPatchProductColor, TPostProductColor, TProductColor, TQueryProductColor } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import {
  createProductColor,
  deleteProductColor,
  getListProductColor,
  getProductColorById,
  patchProductColor,
} from '../apis'
import { DETAIL_PRODUCT_COLOR, GET_LIST_PRODUCT_COLOR } from '../keys'

export const useCreateProductColor = () => {
  const accessToken = checkAuth()
  return useMutation((body: TPostProductColor) => createProductColor(body, accessToken), {
    onSuccess: (res: TResApi<TProductColor>) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_COLOR])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useQueryListProductColor = (params: TQueryProductColor) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TProductColor[]>>(
    [GET_LIST_PRODUCT_COLOR, JSON.stringify(params)],
    () => getListProductColor(params, accessToken),
    { enabled: !!accessToken },
  )
}

export const useQueryProductColorById = (id: number) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TProductColor>>([DETAIL_PRODUCT_COLOR, id], () => getProductColorById(id, accessToken), {
    enabled: !!accessToken && !!id,
  })
}

export const useMutationPatchProductColorById = () => {
  const accessToken = checkAuth()
  return useMutation(
    ({ id, data }: { id: number; data: TPatchProductColor }) => patchProductColor(id, data, accessToken),
    {
      onSuccess: (res: TResApi, { id }) => {
        queryClient.refetchQueries([GET_LIST_PRODUCT_COLOR])
        queryClient.invalidateQueries([DETAIL_PRODUCT_COLOR, id])
        notification.success({ message: NSuccess, description: res?.message })
      },
      onError: (error: TResApiErr) => {
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
}

export const useMutationRemoveProductColorById = () => {
  const accessToken = checkAuth()
  return useMutation((id: number) => deleteProductColor(id, accessToken), {
    onSuccess: (res: TResApi, id) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_COLOR])
      queryClient.removeQueries([DETAIL_PRODUCT_COLOR, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}
