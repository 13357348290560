import { TPatchDiscountRule, TPostDiscountRule, TQueryDiscountRule } from '@src/modules'

import { request } from './config'

export const createDiscount = (body: TPostDiscountRule, token: string) =>
  request({ url: 'discount', method: 'POST', data: body }, { token })
export const getListDiscount = (params: TQueryDiscountRule, token: string) =>
  request({ url: 'discount', method: 'GET', params }, { token })
export const getDiscountById = (id: number, token: string) =>
  request({ url: `discount/${id}`, method: 'GET' }, { token })
export const patchDiscount = (id: number, body: TPatchDiscountRule, token: string) =>
  request({ url: `discount/${id}`, method: 'PATCH', data: body }, { token })
export const deleteDiscount = (id: number, token: string) =>
  request({ url: `discount/${id}`, method: 'DELETE' }, { token })
