import { TPatchProductDensity, TPostProductDensity, TQueryProductDensity } from '@src/modules'

import { request } from './config'

export const createProductDensity = (body: TPostProductDensity, token: string) =>
  request({ url: 'product-density', method: 'POST', data: body }, { token })
export const getListProductDensity = (params: TQueryProductDensity, token: string) =>
  request({ url: 'product-density', method: 'GET', params }, { token })
export const getProductDensityById = (id: number, token: string) =>
  request({ url: `product-density/${id}`, method: 'GET' }, { token })
export const patchProductDensity = (id: number, body: TPatchProductDensity, token: string) =>
  request({ url: `product-density/${id}`, method: 'PATCH', data: body }, { token })
export const deleteProductDensity = (id: number, token: string) =>
  request({ url: `product-density/${id}`, method: 'DELETE' }, { token })
