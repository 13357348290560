import { NError, NSuccess } from '@src/configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TPatchProductSurface, TPostProductSurface, TProductSurface, TQueryProductSurface } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import {
  createProductSurface,
  deleteProductSurface,
  getListProductSurface,
  getProductSurfaceById,
  patchProductSurface,
} from '../apis'
import { DETAIL_PRODUCT_SURFACE, GET_LIST_PRODUCT_SURFACE } from '../keys'

export const useCreateProductSurface = () => {
  const accessToken = checkAuth()
  return useMutation((body: TPostProductSurface) => createProductSurface(body, accessToken), {
    onSuccess: (res: TResApi<TProductSurface>) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_SURFACE])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useQueryListProductSurface = (params: TQueryProductSurface) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TProductSurface[]>>(
    [GET_LIST_PRODUCT_SURFACE, JSON.stringify(params)],
    () => getListProductSurface(params, accessToken),
    { enabled: !!accessToken },
  )
}

export const useQueryProductSurfaceById = (id: number) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TProductSurface>>(
    [DETAIL_PRODUCT_SURFACE, id],
    () => getProductSurfaceById(id, accessToken),
    {
      enabled: !!accessToken && !!id,
    },
  )
}

export const useMutationPatchProductSurfaceById = () => {
  const accessToken = checkAuth()
  return useMutation(
    ({ id, data }: { id: number; data: TPatchProductSurface }) => patchProductSurface(id, data, accessToken),
    {
      onSuccess: (res: TResApi, { id }) => {
        queryClient.refetchQueries([GET_LIST_PRODUCT_SURFACE])
        queryClient.invalidateQueries([DETAIL_PRODUCT_SURFACE, id])
        notification.success({ message: NSuccess, description: res?.message })
      },
      onError: (error: TResApiErr) => {
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
}

export const useMutationRemoveProductSurfaceById = () => {
  const accessToken = checkAuth()
  return useMutation((id: number) => deleteProductSurface(id, accessToken), {
    onSuccess: (res: TResApi, id) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_SURFACE])
      queryClient.removeQueries([DETAIL_PRODUCT_SURFACE, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}
