import { TPatchProductPanel, TPostProductPanel, TQueryProductPanel } from '@src/modules'

import { request } from './config'

export const createProductPanel = (body: TPostProductPanel, token: string) =>
  request({ url: 'product-panel', method: 'POST', data: body }, { token })
export const getListProductPanel = (params: TQueryProductPanel, token: string) =>
  request({ url: 'product-panel', method: 'GET', params }, { token })
export const getProductPanelById = (id: number, token: string) =>
  request({ url: `product-panel/${id}`, method: 'GET' }, { token })
export const patchProductPanel = (id: number, body: TPatchProductPanel, token: string) =>
  request({ url: `product-panel/${id}`, method: 'PATCH', data: body }, { token })
export const deleteProductPanel = (id: number, token: string) =>
  request({ url: `product-panel/${id}`, method: 'DELETE' }, { token })
