import { TPatchCategory, TPostCategory, TQueryCategory } from '@src/modules'

import { request } from './config'

export const createCategory = (body: TPostCategory, token: string) =>
  request({ url: 'category', method: 'POST', data: body }, { token })
export const getListCategory = (params: TQueryCategory, token: string) =>
  request({ url: 'category', method: 'GET', params }, { token })
export const getCategoryById = (id: number, token: string) =>
  request({ url: `category/${id}`, method: 'GET' }, { token })
export const patchCategory = (id: number, body: TPatchCategory, token: string) =>
  request({ url: `category/${id}`, method: 'PATCH', data: body }, { token })
export const deleteCategory = (id: number, token: string) =>
  request({ url: `category/${id}`, method: 'DELETE' }, { token })
