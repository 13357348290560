import { ACCESS } from '@src/configs/permission'
import lazyLoading from '@src/components/widgets/LazyLoading'

import { TRouteConfig } from '../routeConfig'

const List = lazyLoading(() => import('@src/pages/product-property/size'))
const Create = lazyLoading(() => import('@src/pages/product-property/size/create'))
const Detail = lazyLoading(() => import('@src/pages/product-property/size/detail'))

const sizeRouter: TRouteConfig[] = [
  {
    path: '/size',
    Element: List,
    key: ACCESS.LIST_SIZE,
  },
  {
    path: '/create-size',
    Element: Create,
    key: ACCESS.ADD_SIZE,
  },
  {
    path: '/size/:id',
    Element: Detail,
    key: ACCESS.VIEW_SIZE,
  },
]

export { sizeRouter }
