import { ACCESS } from '@src/configs/permission'
import lazyLoading from '@src/components/widgets/LazyLoading'

import { TRouteConfig } from '../routeConfig'

const List = lazyLoading(() => import('@src/pages/product-property/foam'))
const Create = lazyLoading(() => import('@src/pages/product-property/foam/create'))
const Detail = lazyLoading(() => import('@src/pages/product-property/foam/detail'))

const foamRouter: TRouteConfig[] = [
  {
    path: '/foam',
    Element: List,
    key: ACCESS.LIST_FOAM,
  },
  {
    path: '/create-foam',
    Element: Create,
    key: ACCESS.ADD_FOAM,
  },
  {
    path: '/foam/:id',
    Element: Detail,
    key: ACCESS.VIEW_FOAM,
  },
]

export { foamRouter }
