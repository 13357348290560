import { ACCESS } from '@src/configs/permission'
import lazyLoading from '@src/components/widgets/LazyLoading'

import { TRouteConfig } from '../routeConfig'

const List = lazyLoading(() => import('@src/pages/product-property/color'))
const Create = lazyLoading(() => import('@src/pages/product-property/color/create'))
const Detail = lazyLoading(() => import('@src/pages/product-property/color/detail'))

const colorRouter: TRouteConfig[] = [
  {
    path: '/color',
    Element: List,
    key: ACCESS.LIST_COLOR,
  },
  {
    path: '/create-color',
    Element: Create,
    key: ACCESS.ADD_COLOR,
  },
  {
    path: '/color/:id',
    Element: Detail,
    key: ACCESS.VIEW_COLOR,
  },
]

export { colorRouter }
