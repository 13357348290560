import { NError, NSuccess } from '@src/configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TPatchProductAccessory, TPostProductAccessory, TProductAccessory, TQueryProductAccessory } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import {
  createProductAccessory,
  deleteProductAccessory,
  getListProductAccessory,
  getProductAccessoryById,
  patchProductAccessory,
} from '../apis'
import { DETAIL_PRODUCT_ACCESSORY, GET_LIST_PRODUCT_ACCESSORY } from '../keys'

export const useCreateProductAccessory = () => {
  const accessToken = checkAuth()
  return useMutation((body: TPostProductAccessory) => createProductAccessory(body, accessToken), {
    onSuccess: (res: TResApi<TProductAccessory>) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_ACCESSORY])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useQueryListProductAccessory = (params: TQueryProductAccessory) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TProductAccessory[]>>(
    [GET_LIST_PRODUCT_ACCESSORY, JSON.stringify(params)],
    () => getListProductAccessory(params, accessToken),
    { enabled: !!accessToken },
  )
}

export const useQueryProductAccessoryById = (id: number) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TProductAccessory>>(
    [DETAIL_PRODUCT_ACCESSORY, id],
    () => getProductAccessoryById(id, accessToken),
    {
      enabled: !!accessToken && !!id,
    },
  )
}

export const useMutationPatchProductAccessoryById = () => {
  const accessToken = checkAuth()
  return useMutation(
    ({ id, data }: { id: number; data: TPatchProductAccessory }) => patchProductAccessory(id, data, accessToken),
    {
      onSuccess: (res: TResApi, { id }) => {
        queryClient.refetchQueries([GET_LIST_PRODUCT_ACCESSORY])
        queryClient.invalidateQueries([DETAIL_PRODUCT_ACCESSORY, id])
        notification.success({ message: NSuccess, description: res?.message })
      },
      onError: (error: TResApiErr) => {
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
}

export const useMutationRemoveProductAccessoryById = () => {
  const accessToken = checkAuth()
  return useMutation((id: number) => deleteProductAccessory(id, accessToken), {
    onSuccess: (res: TResApi, id) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_ACCESSORY])
      queryClient.removeQueries([DETAIL_PRODUCT_ACCESSORY, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}
