import { NError, NSuccess } from '@src/configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TPatchProductFoam, TPostProductFoam, TProductFoam, TQueryProductFoam } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import { createProductFoam, deleteProductFoam, getListProductFoam, getProductFoamById, patchProductFoam } from '../apis'
import { DETAIL_PRODUCT_FOAM, GET_LIST_PRODUCT_FOAM } from '../keys'

export const useCreateProductFoam = () => {
  const accessToken = checkAuth()
  return useMutation((body: TPostProductFoam) => createProductFoam(body, accessToken), {
    onSuccess: (res: TResApi<TProductFoam>) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_FOAM])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useQueryListProductFoam = (params: TQueryProductFoam) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TProductFoam[]>>(
    [GET_LIST_PRODUCT_FOAM, JSON.stringify(params)],
    () => getListProductFoam(params, accessToken),
    { enabled: !!accessToken },
  )
}

export const useQueryProductFoamById = (id: number) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TProductFoam>>([DETAIL_PRODUCT_FOAM, id], () => getProductFoamById(id, accessToken), {
    enabled: !!accessToken && !!id,
  })
}

export const useMutationPatchProductFoamById = () => {
  const accessToken = checkAuth()
  return useMutation(
    ({ id, data }: { id: number; data: TPatchProductFoam }) => patchProductFoam(id, data, accessToken),
    {
      onSuccess: (res: TResApi, { id }) => {
        queryClient.refetchQueries([GET_LIST_PRODUCT_FOAM])
        queryClient.invalidateQueries([DETAIL_PRODUCT_FOAM, id])
        notification.success({ message: NSuccess, description: res?.message })
      },
      onError: (error: TResApiErr) => {
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
}

export const useMutationRemoveProductFoamById = () => {
  const accessToken = checkAuth()
  return useMutation((id: number) => deleteProductFoam(id, accessToken), {
    onSuccess: (res: TResApi, id) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_FOAM])
      queryClient.removeQueries([DETAIL_PRODUCT_FOAM, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}
