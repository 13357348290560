import { TPatchProductAccessory, TPostProductAccessory, TQueryProductAccessory } from '@src/modules'

import { request } from './config'

export const createProductAccessory = (body: TPostProductAccessory, token: string) =>
  request({ url: 'product-accessory', method: 'POST', data: body }, { token })
export const getListProductAccessory = (params: TQueryProductAccessory, token: string) =>
  request({ url: 'product-accessory', method: 'GET', params }, { token })
export const getProductAccessoryById = (id: number, token: string) =>
  request({ url: `product-accessory/${id}`, method: 'GET' }, { token })
export const patchProductAccessory = (id: number, body: TPatchProductAccessory, token: string) =>
  request({ url: `product-accessory/${id}`, method: 'PATCH', data: body }, { token })
export const deleteProductAccessory = (id: number, token: string) =>
  request({ url: `product-accessory/${id}`, method: 'DELETE' }, { token })
