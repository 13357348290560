export enum ACCESS {
  GENERAL = 'GENERAL',

  // ROLE
  ADD_ROLE = 'ADD_ROLE',
  VIEW_ROLE = 'VIEW_ROLE',
  LIST_ROLE = 'LIST_ROLE',
  EDIT_ROLE = 'EDIT_ROLE',
  DELETE_ROLE = 'DELETE_ROLE',

  // User
  ADD_USER = 'ADD_USER',
  VIEW_USER = 'VIEW_USER',
  LIST_USER = 'LIST_USER',
  EDIT_USER = 'EDIT_USER',
  DELETE_USER = 'DELETE_USER',

  // Feedback
  ADD_FEEDBACK = 'ADD_FEEDBACK',
  VIEW_FEEDBACK = 'VIEW_FEEDBACK',
  LIST_FEEDBACK = 'LIST_FEEDBACK',
  EDIT_FEEDBACK = 'EDIT_FEEDBACK',
  DELETE_FEEDBACK = 'DELETE_FEEDBACK',

  // Sales Order
  ADD_SALES_ORDER = 'ADD_SALES_ORDER',
  VIEW_SALES_ORDER = 'VIEW_SALES_ORDER',
  LIST_SALES_ORDER = 'LIST_SALES_ORDER',
  EDIT_SALES_ORDER = 'EDIT_SALES_ORDER',
  DELETE_SALES_ORDER = 'DELETE_SALES_ORDER',

  // Color
  ADD_COLOR = 'ADD_COLOR',
  VIEW_COLOR = 'VIEW_COLOR',
  LIST_COLOR = 'LIST_COLOR',
  EDIT_COLOR = 'EDIT_COLOR',
  DELETE_COLOR = 'DELETE_COLOR',

  // Employee
  ADD_EMPLOYEE = 'ADD_EMPLOYEE',
  VIEW_EMPLOYEE = 'VIEW_EMPLOYEE',
  LIST_EMPLOYEE = 'LIST_EMPLOYEE',
  EDIT_EMPLOYEE = 'EDIT_EMPLOYEE',
  DELETE_EMPLOYEE = 'DELETE_EMPLOYEE',

  // Item
  ADD_ITEM = 'ADD_ITEM',
  VIEW_ITEM = 'VIEW_ITEM',
  LIST_ITEM = 'LIST_ITEM',
  EDIT_ITEM = 'EDIT_ITEM',
  DELETE_ITEM = 'DELETE_ITEM',

  // Notification
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  VIEW_NOTIFICATION = 'VIEW_NOTIFICATION',
  LIST_NOTIFICATION = 'LIST_NOTIFICATION',
  EDIT_NOTIFICATION = 'EDIT_NOTIFICATION',
  DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',

  // Territory
  ADD_TERRITORY = 'ADD_TERRITORY',
  VIEW_TERRITORY = 'VIEW_TERRITORY',
  LIST_TERRITORY = 'LIST_TERRITORY',
  EDIT_TERRITORY = 'EDIT_TERRITORY',
  DELETE_TERRITORY = 'DELETE_TERRITORY',

  // Thickness
  ADD_THICKNESS = 'ADD_THICKNESS',
  VIEW_THICKNESS = 'VIEW_THICKNESS',
  LIST_THICKNESS = 'LIST_THICKNESS',
  EDIT_THICKNESS = 'EDIT_THICKNESS',
  DELETE_THICKNESS = 'DELETE_THICKNESS',

  // Report
  VIEW_REPORT = 'VIEW_REPORT',
  LIST_REPORT = 'LIST_REPORT',

  // Taxonomy
  ADD_TAXONOMY = 'ADD_TAXONOMY',
  VIEW_TAXONOMY = 'VIEW_TAXONOMY',
  LIST_TAXONOMY = 'LIST_TAXONOMY',
  EDIT_TAXONOMY = 'EDIT_TAXONOMY',
  DELETE_TAXONOMY = 'DELETE_TAXONOMY',

  // Post
  ADD_POST = 'ADD_POST',
  VIEW_POST = 'VIEW_POST',
  LIST_POST = 'LIST_POST',
  EDIT_POST = 'EDIT_POST',
  DELETE_POST = 'DELETE_POST',

  // Media Module
  CREATE_MEDIA = 'CREATE_MEDIA',
  LIST_MEDIAS = 'LIST_MEDIAS',
  VIEW_MEDIA = 'VIEW_MEDIA',
  UPDATE_MEDIA = 'UPDATE_MEDIA',
  DELETE_MEDIA = 'DELETE_MEDIA',

  // Discount rule
  ADD_DISCOUNT = 'ADD_DISCOUNT',
  VIEW_DISCOUNT = 'VIEW_DISCOUNT',
  LIST_DISCOUNT = 'LIST_DISCOUNT',
  EDIT_DISCOUNT = 'EDIT_DISCOUNT',
  DELETE_DISCOUNT = 'DELETE_DISCOUNT',

  // Order
  ADD_ORDER = 'ADD_ORDER',
  VIEW_ORDER = 'VIEW_ORDER',
  LIST_ORDER = 'LIST_ORDER',
  EDIT_ORDER = 'EDIT_ORDER',
  DELETE_ORDER = 'DELETE_ORDER',
  APPROVE_ORDER = 'APPROVE_ORDER',

  // Campaign
  ADD_CAMPAIGN = 'ADD_CAMPAIGN',
  VIEW_CAMPAIGN = 'VIEW_CAMPAIGN',
  LIST_CAMPAIGN = 'LIST_CAMPAIGN',
  EDIT_CAMPAIGN = 'EDIT_CAMPAIGN',
  DELETE_CAMPAIGN = 'DELETE_CAMPAIGN',

  // Surface
  ADD_SURFACE = 'ADD_SURFACE',
  VIEW_SURFACE = 'VIEW_SURFACE',
  LIST_SURFACE = 'LIST_SURFACE',
  EDIT_SURFACE = 'EDIT_SURFACE',
  DELETE_SURFACE = 'DELETE_SURFACE',

  // Panel
  ADD_PANEL = 'ADD_PANEL',
  VIEW_PANEL = 'VIEW_PANEL',
  LIST_PANEL = 'LIST_PANEL',
  EDIT_PANEL = 'EDIT_PANEL',
  DELETE_PANEL = 'DELETE_PANEL',

  // Density
  ADD_DENSITY = 'ADD_DENSITY',
  VIEW_DENSITY = 'VIEW_DENSITY',
  LIST_DENSITY = 'LIST_DENSITY',
  EDIT_DENSITY = 'EDIT_DENSITY',
  DELETE_DENSITY = 'DELETE_DENSITY',

  // Foam
  ADD_FOAM = 'ADD_FOAM',
  VIEW_FOAM = 'VIEW_FOAM',
  LIST_FOAM = 'LIST_FOAM',
  EDIT_FOAM = 'EDIT_FOAM',
  DELETE_FOAM = 'DELETE_FOAM',

  // Accessory
  ADD_ACCESSORY = 'ADD_ACCESSORY',
  VIEW_ACCESSORY = 'VIEW_ACCESSORY',
  LIST_ACCESSORY = 'LIST_ACCESSORY',
  EDIT_ACCESSORY = 'EDIT_ACCESSORY',
  DELETE_ACCESSORY = 'DELETE_ACCESSORY',

  // Size
  ADD_SIZE = 'ADD_SIZE',
  VIEW_SIZE = 'VIEW_SIZE',
  LIST_SIZE = 'LIST_SIZE',
  EDIT_SIZE = 'EDIT_SIZE',
  DELETE_SIZE = 'DELETE_SIZE',

  // Product Type
  ADD_PRODUCT_TYPE = 'ADD_PRODUCT_TYPE',
  VIEW_PRODUCT_TYPE = 'VIEW_PRODUCT_TYPE',
  LIST_PRODUCT_TYPE = 'LIST_PRODUCT_TYPE',
  EDIT_PRODUCT_TYPE = 'EDIT_PRODUCT_TYPE',
  DELETE_PRODUCT_TYPE = 'DELETE_PRODUCT_TYPE',

  // Category
  ADD_CATEGORY = 'ADD_CATEGORY',
  VIEW_CATEGORY = 'VIEW_CATEGORY',
  LIST_CATEGORY = 'LIST_CATEGORY',
  EDIT_CATEGORY = 'EDIT_CATEGORY',
  DELETE_CATEGORY = 'DELETE_CATEGORY',

  // Product
  ADD_PRODUCT = 'ADD_PRODUCT',
  VIEW_PRODUCT = 'VIEW_PRODUCT',
  LIST_PRODUCT = 'LIST_PRODUCT',
  EDIT_PRODUCT = 'EDIT_PRODUCT',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
}
