import { NError, NSuccess } from '@src/configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TPatchProductType, TPostProductType, TProductType, TQueryProductType } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import { createProductType, deleteProductType, getListProductType, getProductTypeById, patchProductType } from '../apis'
import { DETAIL_PRODUCT_TYPE, GET_LIST_PRODUCT_TYPE } from '../keys'

export const useCreateProductType = () => {
  const accessToken = checkAuth()
  return useMutation((body: TPostProductType) => createProductType(body, accessToken), {
    onSuccess: (res: TResApi<TProductType>) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_TYPE])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useQueryListProductType = (params: TQueryProductType) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TProductType[]>>(
    [GET_LIST_PRODUCT_TYPE, JSON.stringify(params)],
    () => getListProductType(params, accessToken),
    { enabled: !!accessToken },
  )
}

export const useQueryProductTypeById = (id: number) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TProductType>>([DETAIL_PRODUCT_TYPE, id], () => getProductTypeById(id, accessToken), {
    enabled: !!accessToken && !!id,
  })
}

export const useMutationPatchProductTypeById = () => {
  const accessToken = checkAuth()
  return useMutation(
    ({ id, data }: { id: number; data: TPatchProductType }) => patchProductType(id, data, accessToken),
    {
      onSuccess: (res: TResApi, { id }) => {
        queryClient.refetchQueries([GET_LIST_PRODUCT_TYPE])
        queryClient.invalidateQueries([DETAIL_PRODUCT_TYPE, id])
        notification.success({ message: NSuccess, description: res?.message })
      },
      onError: (error: TResApiErr) => {
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
}

export const useMutationRemoveProductTypeById = () => {
  const accessToken = checkAuth()
  return useMutation((id: number) => deleteProductType(id, accessToken), {
    onSuccess: (res: TResApi, id) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_TYPE])
      queryClient.removeQueries([DETAIL_PRODUCT_TYPE, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}
