import { ACCESS } from '@src/configs/permission'
import lazyLoading from '@src/components/widgets/LazyLoading'

import { TRouteConfig } from '../routeConfig'

const List = lazyLoading(() => import('@src/pages/product-property/panel'))
const Create = lazyLoading(() => import('@src/pages/product-property/panel/create'))
const Detail = lazyLoading(() => import('@src/pages/product-property/panel/detail'))

const panelRouter: TRouteConfig[] = [
  {
    path: '/panel',
    Element: List,
    key: ACCESS.LIST_PANEL,
  },
  {
    path: '/create-panel',
    Element: Create,
    key: ACCESS.ADD_PANEL,
  },
  {
    path: '/panel/:id',
    Element: Detail,
    key: ACCESS.VIEW_PANEL,
  },
]

export { panelRouter }
