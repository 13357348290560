import lazyLoading from '@src/components/widgets/LazyLoading'
import { ACCESS } from '@src/configs/permission'
import React from 'react'
import { PathRouteProps } from 'react-router-dom'

import {
  accessoryRouter,
  campaignRouter,
  colorRouter,
  densityRouter,
  discountRouter,
  foamRouter,
  panelRouter,
  productTypeRouter,
  sizeRouter,
  surfaceRouter,
  thicknessRouter,
} from './router'
import { categoryRouter } from './router/category'

export interface TRouteConfig extends PathRouteProps {
  Element: React.FC
  key: ACCESS
}

const Dashboard = lazyLoading(() => import('@pages/dashboard'))

const Role = lazyLoading(() => import('@pages/role'))

const User = lazyLoading(() => import('@pages/user'))

const Media = lazyLoading(() => import('@pages/media'))

const Post = lazyLoading(() => import('@pages/post'))
const CreatePost = lazyLoading(() => import('@pages/post/create'))
const DetailPost = lazyLoading(() => import('@pages/post/detail'))
const Category = lazyLoading(() => import('@pages/post/category'))

const CreateCategory = lazyLoading(() => import('@pages/post/category/create'))
const DetailCategory = lazyLoading(() => import('@pages/post/category/detail'))

const SalesOrder = lazyLoading(() => import('@pages/sales-order'))

const Order = lazyLoading(() => import('@pages/order'))
const OrderDetail = lazyLoading(() => import('@pages/order/detail'))
const OrderGroup = lazyLoading(() => import('@pages/group-by'))
const GroupOrderInfo = lazyLoading(() => import('@pages/group-by/detail'))
const DirectOrder = lazyLoading(() => import('@pages/direct-by'))
const DetailOrderDirect = lazyLoading(() => import('@pages/direct-by/detail'))
const OrderCampaign = lazyLoading(() => import('@src/pages/group-by/order-campaign'))

const routeConfig: TRouteConfig[] = [
  {
    path: '/',
    Element: Dashboard,
    key: ACCESS.GENERAL,
  },

  // Role
  {
    path: '/role',
    Element: Role,
    key: ACCESS.LIST_ROLE,
  },
  {
    path: '/create-role',
    Element: Role,
    key: ACCESS.ADD_ROLE,
  },
  {
    path: '/role/:id',
    Element: Role,
    key: ACCESS.VIEW_ROLE,
  },

  // User
  {
    path: '/user',
    Element: User,
    key: ACCESS.LIST_USER,
  },

  // Media
  {
    path: '/media',
    Element: Media,
    key: ACCESS.LIST_MEDIAS,
  },

  // Post
  {
    path: '/post',
    Element: Post,
    key: ACCESS.LIST_POST,
  },
  {
    path: '/create-post',
    Element: CreatePost,
    key: ACCESS.ADD_POST,
  },
  {
    path: '/detail-post/:id',
    Element: DetailPost,
    key: ACCESS.VIEW_POST,
  },
  {
    path: '/category',
    Element: Category,
    key: ACCESS.LIST_TAXONOMY,
  },
  {
    path: '/create-category',
    Element: CreateCategory,
    key: ACCESS.LIST_TAXONOMY,
  },
  {
    path: '/detail-category/:id',
    Element: DetailCategory,
    key: ACCESS.LIST_TAXONOMY,
  },

  // Sales Order
  {
    path: '/sales-order',
    Element: SalesOrder,
    key: ACCESS.LIST_SALES_ORDER,
  },

  // Order
  {
    path: '/order',
    Element: Order,
    key: ACCESS.LIST_ORDER,
  },
  {
    path: '/group-by',
    Element: OrderGroup,
    key: ACCESS.LIST_ORDER,
  },
  {
    path: '/group-by/:id',
    Element: GroupOrderInfo,
    key: ACCESS.LIST_ORDER,
  },
  {
    path: '/direct-by',
    Element: DirectOrder,
    key: ACCESS.LIST_ORDER,
  },
  {
    path: '/direct-by/:id',
    Element: DetailOrderDirect,
    key: ACCESS.LIST_ORDER,
  },
  {
    path: '/order/:id',
    Element: OrderDetail,
    key: ACCESS.VIEW_ORDER,
  },
  {
    path: '/order-campaign/:id',
    Element: OrderCampaign,
    key: ACCESS.VIEW_ORDER,
  },
]

const router = routeConfig
  .concat(discountRouter)
  .concat(foamRouter)
  .concat(accessoryRouter)
  .concat(colorRouter)
  .concat(densityRouter)
  .concat(panelRouter)
  .concat(sizeRouter)
  .concat(surfaceRouter)
  .concat(thicknessRouter)
  .concat(productTypeRouter)
  .concat(categoryRouter)
  .concat(campaignRouter)

export default router
