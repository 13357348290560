import { TPatchProductType, TPostProductType, TQueryProductType } from '@src/modules'

import { request } from './config'

export const createProductType = (body: TPostProductType, token: string) =>
  request({ url: 'product-type', method: 'POST', data: body }, { token })
export const getListProductType = (params: TQueryProductType, token: string) =>
  request({ url: 'product-type', method: 'GET', params }, { token })
export const getProductTypeById = (id: number, token: string) =>
  request({ url: `product-type/${id}`, method: 'GET' }, { token })
export const patchProductType = (id: number, body: TPatchProductType, token: string) =>
  request({ url: `product-type/${id}`, method: 'PATCH', data: body }, { token })
export const deleteProductType = (id: number, token: string) =>
  request({ url: `product-type/${id}`, method: 'DELETE' }, { token })
