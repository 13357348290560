import { TPatchProductSize, TPostProductSize, TQueryProductSize } from '@src/modules'

import { request } from './config'

export const createProductSize = (body: TPostProductSize, token: string) =>
  request({ url: 'product-size', method: 'POST', data: body }, { token })
export const getListProductSize = (params: TQueryProductSize, token: string) =>
  request({ url: 'product-size', method: 'GET', params }, { token })
export const getProductSizeById = (id: number, token: string) =>
  request({ url: `product-size/${id}`, method: 'GET' }, { token })
export const patchProductSize = (id: number, body: TPatchProductSize, token: string) =>
  request({ url: `product-size/${id}`, method: 'PATCH', data: body }, { token })
export const deleteProductSize = (id: number, token: string) =>
  request({ url: `product-size/${id}`, method: 'DELETE' }, { token })
