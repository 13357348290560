import { NError, NSuccess } from '@src/configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TPatchProductSize, TPostProductSize, TProductSize, TQueryProductSize } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import { createProductSize, deleteProductSize, getListProductSize, getProductSizeById, patchProductSize } from '../apis'
import { DETAIL_PRODUCT_SIZE, GET_LIST_PRODUCT_SIZE } from '../keys'

export const useCreateProductSize = () => {
  const accessToken = checkAuth()
  return useMutation((body: TPostProductSize) => createProductSize(body, accessToken), {
    onSuccess: (res: TResApi<TProductSize>) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_SIZE])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useQueryListProductSize = (params: TQueryProductSize) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TProductSize[]>>(
    [GET_LIST_PRODUCT_SIZE, JSON.stringify(params)],
    () => getListProductSize(params, accessToken),
    { enabled: !!accessToken },
  )
}

export const useQueryProductSizeById = (id: number) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TProductSize>>([DETAIL_PRODUCT_SIZE, id], () => getProductSizeById(id, accessToken), {
    enabled: !!accessToken && !!id,
  })
}

export const useMutationPatchProductSizeById = () => {
  const accessToken = checkAuth()
  return useMutation(
    ({ id, data }: { id: number; data: TPatchProductSize }) => patchProductSize(id, data, accessToken),
    {
      onSuccess: (res: TResApi, { id }) => {
        queryClient.refetchQueries([GET_LIST_PRODUCT_SIZE])
        queryClient.invalidateQueries([DETAIL_PRODUCT_SIZE, id])
        notification.success({ message: NSuccess, description: res?.message })
      },
      onError: (error: TResApiErr) => {
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
}

export const useMutationRemoveProductSizeById = () => {
  const accessToken = checkAuth()
  return useMutation((id: number) => deleteProductSize(id, accessToken), {
    onSuccess: (res: TResApi, id) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_SIZE])
      queryClient.removeQueries([DETAIL_PRODUCT_SIZE, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}
