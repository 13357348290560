import { NError, NSuccess } from '@src/configs/const.config'
import { TResApi, TResApiErr, TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TPatchProductPanel, TPostProductPanel, TProductPanel, TQueryProductPanel } from '@src/modules'
import { notification } from 'antd'
import { useMutation, useQuery } from 'react-query'

import { queryClient } from '..'
import {
  createProductPanel,
  deleteProductPanel,
  getListProductPanel,
  getProductPanelById,
  patchProductPanel,
} from '../apis'
import { DETAIL_PRODUCT_PANEL, GET_LIST_PRODUCT_PANEL } from '../keys'

export const useCreateProductPanel = () => {
  const accessToken = checkAuth()
  return useMutation((body: TPostProductPanel) => createProductPanel(body, accessToken), {
    onSuccess: (res: TResApi<TProductPanel>) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_PANEL])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}

export const useQueryListProductPanel = (params: TQueryProductPanel) => {
  const accessToken = checkAuth()
  return useQuery<TResDataListApi<TProductPanel[]>>(
    [GET_LIST_PRODUCT_PANEL, JSON.stringify(params)],
    () => getListProductPanel(params, accessToken),
    { enabled: !!accessToken },
  )
}

export const useQueryProductPanelById = (id: number) => {
  const accessToken = checkAuth()
  return useQuery<TResApi<TProductPanel>>([DETAIL_PRODUCT_PANEL, id], () => getProductPanelById(id, accessToken), {
    enabled: !!accessToken && !!id,
  })
}

export const useMutationPatchProductPanelById = () => {
  const accessToken = checkAuth()
  return useMutation(
    ({ id, data }: { id: number; data: TPatchProductPanel }) => patchProductPanel(id, data, accessToken),
    {
      onSuccess: (res: TResApi, { id }) => {
        queryClient.refetchQueries([GET_LIST_PRODUCT_PANEL])
        queryClient.invalidateQueries([DETAIL_PRODUCT_PANEL, id])
        notification.success({ message: NSuccess, description: res?.message })
      },
      onError: (error: TResApiErr) => {
        notification.error({ message: NError, description: error?.message })
      },
    },
  )
}

export const useMutationRemoveProductPanelById = () => {
  const accessToken = checkAuth()
  return useMutation((id: number) => deleteProductPanel(id, accessToken), {
    onSuccess: (res: TResApi, id) => {
      queryClient.refetchQueries([GET_LIST_PRODUCT_PANEL])
      queryClient.removeQueries([DETAIL_PRODUCT_PANEL, id])
      notification.success({ message: NSuccess, description: res?.message })
    },
    onError: (error: TResApiErr) => {
      notification.error({ message: NError, description: error?.message })
    },
  })
}
