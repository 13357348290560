import { ETypeCampaign, TPatchCampaign, TPostCampaign, TQueryCampaign } from '@src/modules/campaign'

import { request } from './config'

export const createCampaign = (body: TPostCampaign, token: string) =>
  request({ url: 'campaign', method: 'POST', data: body }, { token })
export const getListCampaign = (params: TQueryCampaign, token: string) =>
  request({ url: 'campaign', method: 'GET', params }, { token })
export const findCurrentCampaign = (type: ETypeCampaign, token: string) =>
  request({ url: 'campaign/current', method: 'GET', params: { type } }, { token })
export const getCampaignById = (id: number, token: string) =>
  request({ url: `campaign/${id}`, method: 'GET' }, { token })
export const patchCampaign = (id: number, data: TPatchCampaign, token: string) =>
  request({ url: `campaign/${id}`, method: 'PATCH', data }, { token })
export const deleteCampaign = (id: number, token: string) =>
  request({ url: `campaign/${id}`, method: 'DELETE' }, { token })
