import { TResDataListApi } from '@src/configs/interface.config'
import { checkAuth } from '@src/libs/localStorage'
import { TQuerySalesOrder, TSalesOrder } from '@src/modules/sales-order'
import { useQuery } from 'react-query'

import { LIST_SALES_ORDER } from '../keys/sales-order'
import { getListSalesOrder } from '../apis/sales-order'

export const useQueryGetListSalesOrder = (params: TQuerySalesOrder, token?: string) => {
  const accessToken = token || checkAuth()
  return useQuery<TResDataListApi<TSalesOrder[]>>(
    [LIST_SALES_ORDER, JSON.stringify(params)],
    () => getListSalesOrder(params, accessToken),
    {
      enabled: !!accessToken,
    },
  )
}
